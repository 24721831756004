
import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { Routes } from "routes";

import Presentation from "pages/Presentation";
import NotFoundPage from "pages/NotFound";
import ServerError from "pages/ServerError";
import Report from './Report';

const PageSwitch = () => {

  const location = useLocation();
  useEffect(() => {
    //ReactGA.send({ hitType: "pageview", page: location.pathname + location.search});
  }, [location]);

  return (
    <Switch>
      <Route exact path={Routes.Presentation.path} component={Presentation} />
      <Route exact path={Routes.NotFound.path} component={NotFoundPage} />
      <Route exact path={Routes.ServerError.path} component={ServerError} />
      <Route exact path={Routes.Report.path + "/:id"} component={Report} />

      <Redirect to={Routes.NotFound.path} />
    </Switch>
  );

}

export default () => (
  <PageSwitch/>
);
