import React, { useState, useEffect } from "react";

import { ChevronLeftIcon } from "@heroicons/react/solid";
import { Col, Row, Button, Container, Table, FormCheck, Dropdown, ButtonGroup, Card, Form, OverlayTrigger, Tooltip, ProgressBar, Alert } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import { AdjustmentsIcon, ArchiveIcon, CheckIcon, CogIcon, SearchIcon } from "@heroicons/react/solid";
import { DotsHorizontalIcon, XCircleIcon } from "@heroicons/react/solid";

import { Routes } from "routes";
import { useTranslation } from "react-i18next";
import Footer from "components/Footer";
import Header from "components/Header";
import {Helmet} from "react-helmet";

import { capitalizeFirstLetter, timestampToString } from "util/util";
import getBackend from "backend/backend";
import "../styles/custom.css";

const AlertsTable = (props) => {
    const { alerts = [], allSelected } = props;

    const AlertTableRow = (props) => {
        const { alert } = props;
        const status = alert.severity === "low" ? "primary"
        : alert.severity === "medium" ? "warning"
            : alert.severity === "high" ? "danger"
                : alert.severity === "critical" ? "critical" : "primary";

        const shortenString = (str, maxLength) => {
            if (str.length <= maxLength) {
                return str;
            }
            return str.substring(0, maxLength - 3) + "...";
        }

        const getAlertDetails = (alert) => {
            return shortenString(alert.description, 80);
        }

        return (
            <tr className="border-bottom">  
                <td>
                <span className={`fw-normal text-${status}`}>
                    {capitalizeFirstLetter(alert.severity)}
                </span>
                </td>

                <td><span className="fw-normal">{alert.path}</span></td>

                <td title={alert.description}>
                <span className="fw-normal">{getAlertDetails(alert)}</span>&nbsp;
                </td>

            </tr>
        );
    };

    return (
      <Card border="0" className="table-wrapper table-responsive shadow">
        <Card.Body>
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">Severity</th>
                <th className="border-bottom">File</th>
                <th className="border-bottom">Description</th>
              </tr>
            </thead>
            <tbody className="border-0">
              {alerts.map(u => <AlertTableRow key={`alert-${u.id}`} alert={u} />)}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  };


const ReportProgressBar = (props) => {
    return (
        <ProgressBar animated now={props.progress} label={`${props.progress}%`} style={{ minHeight: '30px' }}/>
    );
}

export default ({match}) => {
    const report_id = match.params.id;
    const [issues, setIssues] = useState([]);
    const [progress, setProgress] = useState(3);
    const [url, setUrl] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        let canceled = false;
    
        const sortIssuesBySeverity = (issues) => {
            const severityOrder = ['critical', 'high', 'medium', 'low'];
            return issues.sort((a, b) => severityOrder.indexOf(a.severity) - severityOrder.indexOf(b.severity));
        };

        const getTask = async () => {
          const result = await getBackend().getTask(report_id);
          if (canceled)
            return;
    
          let issues = [];
          if (result.error == null) {
            setUrl(result.response.url);
            setProgress(result.response.progress);
            setError(result.response.internal_error);
            if (result.response.progress >= 100 && result.response.report !== null) {
                for (let path in result.response.report) {
                    let fileIssues = result.response.report[path].issues;
                    if (fileIssues != null) {
                      issues = issues.concat(fileIssues.map(u => ({...u, path: path})));
                    }
                }
                setIssues(sortIssuesBySeverity(issues.map((u, index) => ({ ...u, isSelected: false, show: true, timestamp: 0, id: index}))));
            }
            return result.response.progress;
          } else {
              window.location.href = Routes.NotFound.path;
          }
        };
    
        const timer = setInterval(async () => {
            if (!canceled) {
                const progress = await getTask();
                if (progress >= 100) {
                    clearInterval(timer);
                }    
            }
        }, 1000); // Update every 1 second
   
        return async () => {
          clearInterval(timer);
          canceled = true;
        }
    }, [report_id]);

    return (
        <>
          <Helmet>
            <title>Codeinspect AI Report</title>
            <meta name="description" content="Codeinspect AI report" />
          </Helmet>
          <Header/>
          <section className="justify-content-center">
            <Container className="my-5">
                <Row>
                  <Col xs={12} className="text-center">
                    <h1 className="fw-bolder text-primary">
                      Issues of <a href={url}>{url}</a>
                    </h1>
                  </Col>
                </Row>
                {progress < 100 &&
                    <ReportProgressBar progress={progress}/>
                }
                {error !== "" &&
                  <Alert variant="danger" className="text-center">
                      Error: {error}
                  </Alert>
                }
                <AlertsTable alerts={issues}/>
            </Container>
          </section>
          <Footer/>
        </>
      );
}