import React, { useState, useEffect } from "react";
import { Col, Row, Image, Button, Container, Carousel, Form} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import AppControlImg from "assets/img/appcontrol.png";
import EventsImg from "assets/img/events.png";
import BackupsImg from "assets/img/backups.png";
import VolumeFilesImg from "assets/img/volume_files.png";

import {useTranslation} from "react-i18next";
import Header from "components/Header";

import {Helmet} from "react-helmet";
import Footer from "components/Footer";

import ReactGA from "react-ga4";
import getBackend from "backend/backend";

import { Routes } from "routes";

export default () => {
  const [searchValue, setSearchValue] = useState("");

  const changeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const runSearch = async () => {
    const result = await getBackend().createTask(searchValue);
    console.log(result);
    if (result.error == null) {
      window.location.href = Routes.Report.path + "/" + result.response.task_id;
    }
  }

  return (
      <>
        <Helmet>
          <title>Codeinspect AI</title>
          <meta name="description" content="Codeinspect AI presentation"/>
        </Helmet>
        <Header/>
        <section className="section-header pt-3 pb-lg-3 bg-primary text-white overflow-hidden" id="home">
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <h1 className="fw-bolder text-secondary">
                Codeinspect AI
              </h1>
              <p className="text-muted fw-light mb-5 h5">Check Your Source Code for Issues Using AI</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section section-md bg-soft pt-lg-5 vh-100 d-flex" id="features">
        <Container>
          <Row className="mb-3">
                <Col>
                  <Form.Control
                            type="text"
                            placeholder="Enter github.com open repository url to check"
                            value={searchValue}
                            onChange={changeSearchValue}
                        />
                </Col>
                <Col>
                <Button ariant="primary" className="fmxw-200 text-dark rounded animate-up-2 me-3" onClick={(e) => {e.preventDefault(); runSearch();}}>
                    Check<span className="icon icon-xs ms-3" />
                </Button>
                </Col>
          </Row>
        </Container>
      </section>
      <Footer/>
      </>
    );
};
