
// =========================================================
// * Volt Pro React Dashboard
// =========================================================

// * Product Page: https://themesberg.com/product/dashboard/volt-pro-react
// * Copyright 2021 Themesberg (https://www.themesberg.com)
// * License Information (https://themesberg.com/licensing)

// * Designed and coded by https://themesberg.com

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. Please contact us to request a removal.

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// core styles
import "./scss/volt.scss";

// vendor styles
import "leaflet/dist/leaflet.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import "react-datetime/css/react-datetime.css";

import HomePage from "./pages/HomePage";

import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import common_ru from "./translations/ru/common.json";
import common_en from "./translations/en/common.json";
import ReactGA from "react-ga4";

import { GoogleOAuthProvider } from '@react-oauth/google';
import getBackend from 'backend/backend';
import {Helmet} from "react-helmet";


ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <title>Codeinspect</title>
      <meta name="description" content="Codeinspect" />
    </Helmet>
        <BrowserRouter>
            <HomePage />
        </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
