
import React from "react";
import {Button, Container, Navbar, Nav, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "routes";
import CydanixLogo from "assets/img/cydanix.svg";

import { useTranslation } from "react-i18next";

export default (props) => {
  const [t, i18n] = useTranslation('common');

  return (
    <>
      <Navbar variant="dark" expand="lg" bg="dark" className="navbar-transparent navbar-theme-primary sticky-top">
        <Container className="position-relative justify-content-between px-3">
          <Navbar.Brand className="me-lg-3 d-flex align-items-center" as={Link} to={Routes.Presentation.path}>
            <Image src={CydanixLogo} />
            <span className="ms-2 brand-text d-none d-md-inline">Codeinspect AI</span>
          </Navbar.Brand>
          <div className="d-flex align-items-center">
            <Navbar.Collapse id="navbar-default-primary">
            <Nav className="navbar-nav-hover align-items-lg-center">
            <Nav.Link as={Link} to={Routes.Presentation.path}>{t('Home')}</Nav.Link>
            </Nav>
            </Navbar.Collapse>
        </div>
        </Container>
      </Navbar>
    </>
)
}