import HttpClient from './http';
import devmode from './env';
import {delay} from "util/util";

class Backend {
    constructor() {
        this.host_url = (devmode()) ? "http://127.0.0.1:9080" : window.location.origin;
        this.client = new HttpClient(this.host_url);

    }

    async createTask(url) {
        return await this.client.post('/api/task', {url: url}, {});
    }

    async getTask(id) {
        return await this.client.get('/api/task/' + id, {});
    }
}

var backend = null;

function getBackend() {
    if (backend != null)
        return backend;

    backend = new Backend();
    return backend;
}

export default getBackend